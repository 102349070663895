<template>
  <article class="group">
    <component
      :is="card.link ? 'a' : 'button'"
      :href="card.link"
      :rel="isExternalLink(card.link) ? 'external' : undefined"
      :target="isExternalLink(card.link) ? '_blank' : undefined"
      class="flex w-full flex-col gap-5"
    >
      <figure class="w-full">
        <NuxtImg
          :src="card.image.filename"
          :alt="card.image.alt"
          loading="lazy"
          width="384"
          class="ease-out-expo size-full rounded-lg object-cover transition-transform duration-700 group-hover:-translate-y-2"
        />
      </figure>

      <div class="flex flex-col gap-3 px-5">
        <h3 class="text-md font-medium">{{ card.title }}</h3>
        <p
          v-if="card.description"
          class="text-secondary text-sm leading-normal"
        >
          {{ card.description }}
        </p>
      </div>
    </component>
  </article>
</template>

<script setup lang="ts">
import type { AssetStoryblok } from '@/types/storyblok'
import { isExternalLink } from '@/utils/link-utils'

defineProps<{
  card: CardCallToAction
}>()

export interface CardCallToAction {
  _uid: string
  title: string
  description?: string
  image: AssetStoryblok
  link?: string
}
</script>
